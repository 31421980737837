import {useEffect, useMemo, useState} from 'react';
import Head from 'next/head';

import {useRequiredUser} from '../context/UserContext';
import {withAuthentication} from '../components/AuthWrapper';
import Wrapper from '../components/Wrapper';
import Header from '../components/Header';
import CurriculumRecap from '../components/Curriculum/CurriculumRecap';
// import CurriculumBusinessRecap from '../components/Curriculum/CurriculumBusinessRecap';
import CurriculumRoadmap from '../components/Curriculum/CurriculumRoadmap';
import Footer from '../components/Footer';
import {hasCompletedAllCourses} from '../utils/courses';
import {
  fetchContentsFeatured,
  fetchCurricula,
  fetchCurriculum,
} from '../utils/api';
import ContentEvidenceSlider from '../components/Content/ContentEvidenceSlider';
import useSWR from 'swr';
import cx from 'classnames';
import Image from 'next/image';
import Link from 'next/link';

type Courses = {
  courseBase: Course[];
  courseBusiness: Course[];
};

const CoursesPage = () => {
  const {user} = useRequiredUser();
  const [courses, setCourses] = useState<Courses>({
    courseBase: [],
    courseBusiness: [],
  });
  const [curricula, setCurricula] = useState<Curriculum[]>();
  const [loading, setLoading] = useState(true);

  const {curriculum} = user;
  const {courseBase, courseBusiness} = courses;

  const {data: featuredContents} = useSWR(
    '/api/learning-area/featured',
    () => fetchContentsFeatured(),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      revalidateFirstPage: false,
    }
  );

  useEffect(() => {
    (async () => {
      try {
        const [curriculumBase, curriculumBusiness, curricula] =
          await Promise.all([
            fetchCurriculum(user.curriculum.id),
            fetchCurriculum(user.curriculumBusiness.id),
            fetchCurricula(),
          ]);

        setCourses({
          courseBase: curriculumBase.courses,
          courseBusiness: curriculumBusiness.courses,
        });

        //first item of response is always business curriculum
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [business, ...rest] = curricula;
        setCurricula(rest);

        setLoading(false);
      } catch (error) {
        //TODO: handle error
        window.alert(error);
        setLoading(false);
      }
    })();
  }, [user.curriculum.id, user.curriculumBusiness.id]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [featured, suggest] = useMemo(() => {
    return [featuredContents?.featured || [], featuredContents?.suggest || []];
  }, [featuredContents]);

  const hasCompletedBusinessCurriculum = useMemo(
    () => hasCompletedAllCourses(courseBusiness),
    [courseBusiness]
  );

  if (loading) {
    return (
      <Wrapper>
        <div className="flex-1 flex flex-col justify-center items-center p-6">
          <span className="w-10 h-10 border-2 border-brown border-b-lightBrown rounded-full animate-spin" />
        </div>
      </Wrapper>
    );
  }

  return (
    <>
      <Head>
        <title>Allergan</title>
        <meta name="description" content="Corsi" />
      </Head>

      <Wrapper>
        <div className="page-container px-0 lg:px-5">
          <div className="px-5">
            <Header />

            <div className="mb-8 md:text-center">
              <p className="font-title text-xl md:inline md:text-2xl">
                Ciao {user.firstName},&nbsp;
              </p>

              <p className="inline text-sm md:text-xl md:font-regular ">
                ecco i tuoi curriculum attivi. Completali per passare al
                curriculum successivo.
              </p>
            </div>
          </div>

          <div className="relative flex flex-col items-stretch lg:gap-x-5 lg:flex-row lg:px-0">
            <div className="lg:hidden">
              <img
                alt="wave"
                src="/assets/waves/closing-light.png"
                className="absolute block bottom-[-16px] left-0 w-full h-[16px] z-10 md:hidden"
              />
              <img
                alt="wave"
                src="/assets/waves/tablet-closing-light.png"
                className="absolute bottom-[-16px] left-0 w-full h-[16px] z-10 hidden md:block lg:hidden"
              />
            </div>

            <div
              className={cx([
                'relative lg:w-1/2 flex flex-col bg-brown py-11 px-4 lg:bg-transparent lg:p-0',
                !hasCompletedBusinessCurriculum && 'gap-4',
              ])}
            >
              <div className="lg:hidden">
                <img
                  alt="wave"
                  src="/assets/waves/opening-dark.png"
                  className="absolute block top-[-16px] left-0 w-full h-[16px] md:hidden"
                />
                <img
                  alt="wave"
                  src="/assets/waves/tablet-opening-dark.png"
                  className="absolute top-[-16px] left-0 w-full h-[16px] z-10 hidden md:block lg:hidden"
                />
                <img
                  alt="wave"
                  src="/assets/waves/closing-dark.png"
                  className="absolute block bottom-[-16px] left-0 w-full h-[16px] z-10 lg:hidden"
                />
                <img
                  alt="wave"
                  src="/assets/waves/tablet-closing-dark.png"
                  className="absolute bottom-[-16px] left-0 w-full h-[16px] z-10 hidden md:block lg:hidden"
                />
              </div>

              <CurriculumRecap
                id={user.curriculum.id}
                title={curriculum.title}
                courses={courseBase}
                isSmall={!hasCompletedBusinessCurriculum}
              />

              {/* {!hasCompletedBusinessCurriculum && (
                <CurriculumBusinessRecap
                  id={user.curriculumBusiness.id}
                  title={curriculumBusiness.title}
                  description={curriculumBusiness.description}
                  courses={courseBusiness}
                />
              )} */}
            </div>

            <div
              className={cx(
                'flex flex-1 w-full flex-col bg-white px-4 py-11',
                'lg:w-1/2 lg:rounded-[25px] lg:shadow-curriculumCard lg:pt-8 lg:pb-4'
              )}
            >
              <div className="flex flex-1 flex-col">
                <Link
                  href="/learning-area"
                  className="mb-6 px-2 flex justify-between align-top gap-2"
                >
                  <div>
                    <h2 className="m-0 mb-2 text-xl font-medium text-darkBrown">
                      Learning area
                    </h2>
                    <p className="text-darkBrown">
                      Lo spazio dedicato all’approfondimento e alla tua
                      formazione online.
                    </p>
                  </div>
                  <div className="flex items-center justify-center w-6 h-6 rounded-full bg-brown shrink-0">
                    <Image
                      src="/assets/icons/arrow-white.svg"
                      width={5}
                      height={9}
                      alt="freccia"
                    />
                  </div>
                </Link>
                <div className="flex-1">
                  <ContentEvidenceSlider list={featured} />
                </div>
              </div>
            </div>
          </div>

          <CurriculumRoadmap curricula={curricula} />
        </div>

        <Footer />
      </Wrapper>
    </>
  );
};

export default withAuthentication(CoursesPage);
